<template>
  <div>
    <navbar title="我的预约" />
    <div class="order-box">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" offset="50">
        <div class="order-item" v-for="item in itemList" :key="item.id">
          <div class="head">
            <div class="order-time">{{getTimes.timestampToTime(item.userBook.createTime)}}</div>
            <div :class="[item.userBook.status == 0 ? 'active order-label' : 'order-label']">
              {{ item.userBook.status == 0 ? "待接待" : "已接待" }}
            </div>
          </div>

          <div class="order-content" @click="goHouseDetail(item.userBook.houseId,item.userBook.houseType)">
            <div class="order-msg">
              <div class="order-img"> <img :src="item.userBook.userAvatar" alt=""></div>
              <div class="item-box">
                <div class="item-name"><span>【{{item.userBook.houseType == "new" ? '楼盘' : '二手房'}}】</span> {{ item.userBook.houseTitle}}</div>
                <div class="item-area" v-if="item.userBook.houseType == 'new'">{{ item.house.totalArea }}㎡</div>
                <div class="item-price" v-if="item.userBook.houseType == 'new'">{{ item.house.avPrice }}元/㎡</div>


                <div class="item-msg" v-if="item.userBook.houseType != 'new'"> {{item.house.houseArea }}㎡<span>{{item.house.floor}}层/共{{item.house.totalFloor}}层</span></div>
                <div class="item-msg" v-if="item.userBook.houseType != 'new'"><em class="msg-price">{{item.house.price}}万</em>{{item.house.unitPrice}}元/㎡</div>
                <div class="item-words">留言：{{ escapeHtml(item.userBook.content) }}</div>
              </div>

            </div>
          </div>
          <div class="order-bottom" v-if="item.userBook.status==0">
            <div class="order-btn" @click="cancel(item.userBook.id)">
              <van-button type="default">取消</van-button>
            </div>
          </div>
  
        </div>
      </van-list>
    </div>
  </div>
</template>
<script>
import navbar from "../../components/NavBar.vue";
import {escapeHtml } from "../../common/validate";
export default {
  name: "order",
  data() {
    return {
      pageNum: 0,
      pageSize: 10,
      loading: false,
      finished: false,
      itemList: [],
    };
  },
  mounted() {
    // this.getBookingList();
  },
  methods: {
    escapeHtml,
    onLoad() {
      this.loading = true;
      this.pageNum++;
      this.getBookingList();
    },

    getBookingList() {
      const data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      this.$post("user/getBookingList", data).then((res) => {
        if (res.status == 200) {
          console.log(res.data.userBook);
          this.loading = false;
          this.finished = false;
          this.itemList = this.itemList.concat(res.data.userBook);
          console.log(this.itemList);
          let flag = false;
          //防止出现每页楼盘长度等于查询的楼盘长度
          if (
            res.data.userBook.length == res.pageSize &&
            res.data.count % this.pageSize == this.pageNum &&
            res.data.count / this.pageSize == 0
          ) {
            flag = true;
          }
          if (
            this.itemList.length >= res.data.count ||
            res.data.userBook.length < this.pageSize ||
            flag
          ) {
            this.finished = true;
          }
        }else {
          this.$toast.fail(res.msg);
        }
      });
    },
    goHouseDetail(id, houseType) {
      let types = "1";
      console.log(id);
      console.log(houseType);
      if (houseType == "new") {
        this.$router.push({
          path: "/buildingDetail",
          query: {
            id: id,
            types: types,
          },
        });
      } else if (houseType == "second") {
        this.$router.push({
          path: "/SecondHouseDetail",
          query: {
            id: id,
            types: types,
          },
        });
      }
    },
    cancel(id) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "是否确认删除",
        })
        .then(() => {
          console.log(id);
          const data = {
            id: id,
          };
          this.$post("user/delBooking", data).then((res) => {
            if (res.status == 200) {
              var router = this.$toast.success({
                message: "删除成功",
                duration: 1500,
              });
              location.reload();
            }else {
              this.$toast.fail(res.msg);
            }
          });
        })
        .catch(() => {
          console.log(id);
        });
    },
  },
  components: {
    navbar,
  },
};
</script>
<style lang="less" scoped>
.order-box {
  // background-color: #edebeb;
  .order-item {
    background-color: white;
    margin-bottom: 5px;
    .head {
      display: flex;
      justify-content: space-between;
      background-color: #fbfbfb;
      .order-time {
        color: #858585;
        font-size: 13px;
        margin: 10px;
      }
      .order-label {
        font-size: 13px;
        color: #fa474a;
        margin: 9px;
        &.active {
          color: #858585;
        }
      }
    }
    .order-content {
      border-bottom: 1px solid #eee;
      display: flex;
      justify-content: space-between;
      padding-bottom: 2px;
      .order-msg {
        display: flex;
        width: 100%;
        .order-img {
          img {
            width: 122px;
            height: 81px;
            margin: 10px;
          }
          font-size: 15px;
        }
        .item-box {
          font-size: 13px;
          color: #858585;
          padding-right: 10px;
          flex: 1;
          min-width: 0;
          .item-name {
            color: #333;
            font-size: 15px;
            margin-top: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            // padding-top: 0;
          }
          .item-area {
            span {
              margin-left: 15px;
            }
          }
          .item-words{
            height:38px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .item-msg{
            span{
              margin-left: 25px;
            }
            em{
               color: #00d3c4;
              font-size: 13px;
              margin-right: 10px;
            }
          }
        }
      }
    }
    .order-bottom {
      height: 32px;
      display: flex;
      justify-content: flex-end;
      margin-right:6px;
      .van-button--default {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #858585;
        border-radius: 21px;
        color: #858585;
        font-size: 12px;
        width: 77px;
        height: 28px;
        margin-left: 142px;
        margin-top: 2px;
        margin-bottom: 5px;
      }
    }
  }
}
</style>